import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"

const Enrol = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "enrol-image.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Enrol" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>Apply Now</h1>
      </BackgroundImage>
      <div
        id="features"
        className="about__features bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="feature_wrapper option2">
            <div className="row">
              <div className="col-sm-12">
                <h3>
                  New Zealand Diploma in Early Childhood Education and Care
                  (Level 5).
                </h3>
                <p>
                  To begin the enrolment process, please{" "}
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScMXdeEifDxNsjoj94mfQW3kUIZ_hvtHvV0eN24HK2rz4UkEQ/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      window.gtag("event", "apply_online_clicks", {
                        page_name: "enroll",
                        event_type: "apply-online-clicked",
                      })
                    }}
                  >
                    apply online
                  </a>{" "}
                  or email us at{" "}
                  <a href="mailto:info@bodhi.ac.nz">info@bodhi.ac.nz</a> and we
                  will be in touch shortly.
                </p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScMXdeEifDxNsjoj94mfQW3kUIZ_hvtHvV0eN24HK2rz4UkEQ/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                  onClick={() => {
                    window.gtag("event", "apply_online_clicks", {
                      page_name: "enroll",
                      event_type: "apply-online-clicked",
                    })
                  }}
                >
                  Apply Online
                </a>
                <a href="tel:+640211851421" className="btn button-margin-left">
                  021 185 1421
                </a>
              </div>
              <div className="col-sm-12">
                <h3>
                  New Zealand Diploma in Early Childhood Education and Care
                  (Level 6).
                </h3>
                <p>
                  To begin the enrolment process, please{" "}
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScMXdeEifDxNsjoj94mfQW3kUIZ_hvtHvV0eN24HK2rz4UkEQ/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      window.gtag("event", "apply_online_clicks", {
                        page_name: "enroll",
                        event_type: "apply-online-clicked",
                      })
                    }}
                  >
                    apply online
                  </a>{" "}
                  or email us at{" "}
                  <a href="mailto:info@bodhi.ac.nz">info@bodhi.ac.nz</a> and we
                  will be in touch shortly.
                </p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScMXdeEifDxNsjoj94mfQW3kUIZ_hvtHvV0eN24HK2rz4UkEQ/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                  onClick={() => {
                    window.gtag("event", "apply_online_clicks", {
                      page_name: "enroll",
                      event_type: "apply-online-clicked",
                    })
                  }}
                >
                  Apply Online
                </a>
                <a href="tel:+640211851421" className="btn button-margin-left">
                  021 185 1421
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Enrol
